<template>
  <div class="content">
    <div class="main-screen-section inside screen5"></div>

    <div class="container inside-page content-section">
      <h1>Жизнь с мигренью<ReferenceLink text="1-3" href="#ref1" /></h1>
      <div class="content-text text-size-big mb20">
        <p>
          Мигрень – это хроническое заболевание, приводящее к физической
          <br class="hidden-xs" />
          и психологической нетрудоспособности, которое нарушает способность
          человека выполнять повседневную деятельность и тем самым оказывает
          отрицательное влияние на качество жизни пациента.
        </p>
      </div>

      <div class="migrain-perc">
        <div class="row">
          <div class="col-md-4">
            <div class="m-perc-block">
              <div class="num">92%</div>
              <div class="text">
                Опрошенных пациентов <br class="hidden-xs" />
                с мигренью уверены, что это <br class="hidden-xs" />
                заболевание отрицательно влияет <br class="hidden-xs" />
                на общее качество их жизни
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="m-perc-block">
              <div class="num">69%</div>
              <div class="text">
                Утверждают, что мигрень повлияла <br />
                на их социальную жизнь
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="m-perc-block">
              <div class="num">56%</div>
              <div class="text">
                Утверждают, что мигрень повлияла <br />
                на работу/карьеру
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="content-text text-size-middle">
        <h3>Влияние на разные аспекты жизни</h3>
        <p>
          Результаты исследования с участием 12 545 пациентов с мигренью,
          проведенного в 16 странах, показали, что люди, страдающие от мигрени,
          вынуждены справляться не только с физическими симптомами болезни, но и
          с воздействием, которое это состояние оказывает на многие аспекты их
          повседневной жизни.
        </p>
      </div>

      <div class="life-mig">
        <div class="sub-text">
          «Ценю каждый<br />
          момент жизни <br />без мигрени»
        </div>
        <img src="img/pic-life.jpg" class="img-responsive" alt="img" />
      </div>

      <div class="content-text text-size-middle">
        <ul class="list-block mb40">
          <li>
            <p>
              Мигрень приводит к существенному снижению функциональности во
              время и между приступами.
            </p>
          </li>
          <li>
            <p>
              &lt;43% пациентов с мигренью утверждают, что полностью
              восстанавливаются в период между приступами.
            </p>
          </li>
          <li>
            <p>
              У пациентов с мигренью существенно понижено связанное со здоровьем
              качество жизни (СЗКЖ), чем в <br />
              общей популяции без хронических болезней.
            </p>
          </li>
          <li>
            <p>По мере повышения нетрудоспособности снижается СЗКЖ.</p>
          </li>
          <li>
            <p>
              У пациентов с мигренью значительно больше физических симптомов
              (конверсия, сердечно-легочные, <br />
              боль, ЖКТ, сексуальные и пр.) и выше частота психических
              расстройств, чем у пациентов без мигрени.
            </p>
          </li>
        </ul>

        <h3 class="mb30">Влияние мигрени в графике</h3>

        <div class="graph">
          <img src="img/graph.png" alt="img" />
          <div class="influence">
            <div class="title">Влияние мигрени</div>
            <div class="text type1">Очень отрицательное</div>
            <div class="text type2">Довольно отрицательное</div>
            <div class="text type3">Немного отрицательное</div>
          </div>
        </div>
      </div>
      <div class="content-text text-size-middle">
        <h3 class="mb30">Диета</h3>
        <p>
          Диета, по своей патофизиологии, часто подразумевает определение в
          качестве провоцирующих факторов и исключение определенных продуктов. 
          Для избегания пищевых триггеров помогает ведение пищевого дневника
        </p>
        <p>
          Использование диет основано на гипотезе, что употребление в пищу
          определенных продуктов может напрямую нарушать гомеостаз
          нейротрансмиттеров и выделение нейропептидов. Это могут провоцировать
          шоколад, вино, выдержанный сыр,  переработанное мясо,
          стабилизированные продукты и другие продукты, содержащие вазоактивные
          амины (гистамин, тирамин), нитриты, или глутамат натрия.
        </p>
        <p>
          Также кетоновая диета, основанная на ограничении углеводов,
          демонстрирует многообещающие результаты в предупреждении приступов
          мигрени, привлекает большой интерес цефалгологов.
        </p>
        <p>
          Многочисленные исследования подтверждают, что здоровое питание 
          благотворно сказывается на ведении пациентов с мигренью. При
          составлении диеты важно учитывать коморбидный фон и индивидуальные
          предпочтения пациента.
        </p>
      </div>
      <ReferencesList :list="referencesList" />
    </div>
  </div>
</template>

<script>
import ReferenceLink from '../components/TevaReference/ReferenceLink.vue'
import ReferencesList from '../components/TevaReference/ReferencesList.vue'

export default {
  name: 'LivingWithMigraines',
  components: {
    ReferenceLink,
    ReferencesList
  },
  metaInfo: {
    title: 'Жизнь с мигренью',
    meta: [
      {
        name: 'description',
        content:
          'Головные боли одни из распространенных расстройств нервной системы и существенно нарушают качество жизни и трудоспособность'
      }
    ]
  },
  data() {
    return {
      referencesList: [
        {
          id: 'ref1',
          text: 'R.B. Lipton, M.E. Bigal, M. Diamond et al. Migraine prevalence, disease burden, and the need for preventive therapy. Neurology,, 2007; January 30, 68 (5)'
        },
        {
          id: 'ref2',
          text: 'D. D’Amico, S. J. Tepper. Prophylaxis of migraine: general principles and patient acceptance. Neuropsychiatr Dis Treat. 2008 Dec; 4(6):1155-67'
        },
        {
          id: 'ref3',
          text: 'L. J Stovner, E. Nichols, T. J. Steiner et al. Global, regional, and national burden of migraine and tension-type headache, 1990–2016: a systematic analysis for the Global Burden of Disease Study 2016. Lancet Neurol 2018; 17: 954–76'
        }
      ]
    }
  }
}
</script>
